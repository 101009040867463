import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Fade, Zoom } from 'react-awesome-reveal'
import { useForm } from 'react-hook-form'
import { useFirestoreDocData } from 'reactfire'

import Button from 'components/Button'
import Input from 'components/Input'
import SlideContent from 'components/SlideContent'
import TextfitResponsive from 'components/TextfitResponsive'
import hasBadWords from 'utils/hasBadWords'

import Results from './components/Results'

export default function Challenge({
  isChallenge,
  presentation,
  session,
  slide,
  slideId,
  sessionRef,
  uid,
}) {
  const [isProfanity, setIsProfanity] = useState(false)
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false)
  const { t } = useTranslation()
  const { register, handleSubmit, reset } = useForm()
  const userSlideRef = sessionRef
    .collection('slides')
    .doc(slideId)
    .collection('users')
    .doc(uid)

  const slidePollRef = sessionRef.collection('slidePolls').doc(slideId)

  const { data: userSlide } = useFirestoreDocData(userSlideRef)

  const userAnswer = userSlide?.answerArr || []
  const isAnswerShown = userSlide?.isAnswerShown
  const multipleAnswers = slide?.settings?.multipleAnswers || false

  const profanityFilter = presentation?.settings?.profanityFilter

  const onSubmit = async ({ answer }) => {
    if (profanityFilter) {
      if (hasBadWords(answer)) {
        setIsProfanity(true)
        return
      }
      setIsProfanity(false)
    }

    if (multipleAnswers) {
      setIsAnswerSubmitted(true)
      reset()
      setTimeout(() => {
        setIsAnswerSubmitted(false)
      }, 3000)
    }

    userSlideRef.set({
      answerArr: [...userAnswer, answer],
      isAnswerShown: true,
    })

    slidePollRef.set(
      {
        [uid]: [...userAnswer, answer],
      },
      { merge: true },
    )
  }

  const showPollResults =
    isChallenge && isAnswerShown && session?.settings?.showPollResults

  const disabled = userAnswer?.length > 0 && !multipleAnswers

  const answerSubmitted = userAnswer?.length > 0 && !multipleAnswers

  return (
    <div className="flex h-full w-full">
      <div className="flex h-full w-full flex-1 flex-col">
        {showPollResults ? (
          <>
            <div className="flex h-full flex-col">
              <Results
                slide={slide}
                slideId={slideId}
                sessionRef={sessionRef}
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-1 flex-col justify-center">
              <Zoom className="h-full">
                <TextfitResponsive
                  className="formatted-content text-main relative mx-auto flex h-full w-full flex-col justify-center text-gray-900"
                  style={{ lineHeight: '1.125' }}
                  mode="multi"
                >
                  <SlideContent
                    content={slide.content || slide.title}
                    placeholder={t('slide.text')}
                  />
                </TextfitResponsive>
              </Zoom>
            </div>
            {!answerSubmitted && !isAnswerSubmitted ? (
              <Fade>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="mx-auto mt-4 w-full max-w-xl"
                >
                  <div>
                    {profanityFilter && isProfanity && (
                      <div className="text-center text-red-600">
                        Пожалуйста, не ругайтесь
                      </div>
                    )}
                    <Input
                      disabled={disabled}
                      {...register(`answer`)}
                      type="textarea"
                      className="form-input rounded border-red-600"
                      placeholder={t('user.answerForm.yourAnswer')}
                      autoComplete="off"
                      maxLength={200}
                    />
                  </div>
                  {(!isChallenge || (isChallenge && !userAnswer?.length)) && (
                    <div>
                      <Button
                        className="mt-4"
                        disabled={disabled}
                        type="submit"
                        size="lg"
                        variant="success"
                        block
                      >
                        {t('user.answerForm.answer')}
                      </Button>
                    </div>
                  )}
                </form>
              </Fade>
            ) : (
              <Fade>
                <div className="mb-4 text-center opacity-50">
                  Ваш ответ отправлен
                </div>
              </Fade>
            )}
            {multipleAnswers ? (
              <div className="text-center opacity-50">
                Вы можете ответить несколько раз
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  )
}
