import filter from 'leo-profanity'

const hasBadWords = (text) => {
  filter.loadDictionary('en')
  if (filter.check(text)) {
    return true
  }
  filter.loadDictionary('ru')
  return filter.check(text)
}

export default hasBadWords
