import React from 'react'
import { useTranslation } from 'next-i18next'
import { useFirestoreDocData } from 'reactfire'

import SlideContent from 'components/SlideContent'
import TagCloud from 'components/TagCloud'
import TextfitResponsive from 'components/TextfitResponsive'

export default function Slide({ slide, slideId, sessionRef }) {
  const { t } = useTranslation()

  const slidePollRef = sessionRef.collection('slidePolls').doc(slideId)
  const { data: slidePoll } = useFirestoreDocData(slidePollRef)

  if (!slide) {
    return t('common.loading')
  }

  const tags =
    slidePoll &&
    Object.keys(slidePoll)
      .filter((key) => key !== 'NO_ID_FIELD')
      .map((uid) => slidePoll[uid])

  return (
    <div className="flex h-full w-full flex-col justify-center">
      <div className="flex w-full flex-1 flex-col justify-center">
        <div className="flex h-1/4 w-full items-start">
          <TextfitResponsive
            className="formatted-content text-main relative mx-auto flex h-full w-full flex-col justify-center text-gray-900"
            style={{ lineHeight: '1.125' }}
            mode="multi"
          >
            <SlideContent
              content={slide.content || slide.title}
              placeholder={t('slide.questionText')}
            />
          </TextfitResponsive>
        </div>
        <div className="h-full w-full flex-1">
          <TagCloud tags={tags} />
        </div>
      </div>
    </div>
  )
}
