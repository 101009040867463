import React, { useEffect, useState } from 'react'
import { Bars2Icon } from '@heroicons/react/24/outline'
import { arrayMoveImmutable } from 'array-move'
import firebase from 'firebase'
import { useTranslation } from 'next-i18next'
import { Fade, JackInTheBox } from 'react-awesome-reveal'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { useFirestoreDocData } from 'reactfire'

import Button from 'components/Button'
import SlideContent from 'components/SlideContent'
import SlideLayout from 'components/SlideLayout'
import SlideMedia from 'components/SlideMedia'
import TextfitResponsive from 'components/TextfitResponsive'
import Timer from 'components/Timer'
import getCdnUrl from 'utils/getCdnUrl'
import useIsMobile from 'utils/useIsMobile'

const SortableItem = SortableElement(
  ({ index, id, option, buttonVariant, isMobile }) => {
    const { t } = useTranslation()
    return (
      <Button
        key={id}
        variant={buttonVariant}
        index={index}
        block
        className={isMobile ? 'my-1 !cursor-move' : 'mx-2 !cursor-move'}
        size="responsive"
      >
        <span className="flex w-full items-center justify-between">
          <span className="w-full pr-2 text-left">
            {option.imageUrl ? (
              <img
                className="max-h-24"
                src={getCdnUrl(option.imageUrl)}
                alt=""
              />
            ) : (
              option.value || t('slide.option', { option: index + 1 })
            )}
          </span>
          <span>
            <Bars2Icon className="-mr-1 h-5 w-5 text-gray-500" />
          </span>
        </span>
      </Button>
    )
  },
)

const SortableList = SortableContainer(({ isMobile, options, disabled }) => (
  <div className={isMobile ? 'flex flex-col' : 'flex flex-row'}>
    {options.map((option, index) => (
      <SortableItem
        buttonVariant={option.buttonVariant}
        disabled={disabled}
        key={option.id}
        index={index}
        option={option}
        isMobile={isMobile}
      />
    ))}
  </div>
))

export default function Challenge({
  isChallenge,
  presentation,
  session,
  sessionSlide,
  slide,
  slideId,
  sessionRef,
  uid,
  theme,
}) {
  const { t } = useTranslation()
  const [visibility, setVisibility] = useState({})
  const [answerTimestamp, setAnswerTimestamp] = useState(null)
  const [tempOptions, setTempOptions] = useState([])
  const isMobile = useIsMobile()

  const userSlideRef = sessionRef
    .collection('slides')
    .doc(slideId)
    .collection('users')
    .doc(uid)

  const { data: userSlide, status } = useFirestoreDocData(userSlideRef)

  const question = slide?.question || {}
  const options = question?.options || []
  const shuffledOptions = question?.shuffledOptions || []

  const isTimerStarted = isChallenge
    ? userSlide?.isTimerStarted
    : sessionSlide?.isTimerStarted

  const isTimerComplete = isChallenge
    ? userSlide?.isTimerComplete
    : sessionSlide?.isTimerComplete

  const isAnswerShown = isChallenge
    ? userSlide?.isAnswerShown
    : sessionSlide?.isAnswerShown

  const userAnswerArr = userSlide?.answerArr || []

  const getButtonVariant = (optionId) => {
    const tempOptionsIds = tempOptions.map((val) => val.id)
    const optionIds = options.map((val) => val.id)

    if (isAnswerShown) {
      if (tempOptionsIds.indexOf(optionId) === optionIds.indexOf(optionId)) {
        return 'success'
      }
      return 'danger'
    }

    if (tempOptions.includes(optionId)) {
      return 'primary'
    }

    return 'default'
  }

  useEffect(() => {
    setAnswerTimestamp(Date.now())

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        textVisible: true,
      }))
    }, 0)

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        imageVisible: true,
      }))
    }, 300)

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        optionsVisible: true,
      }))
    }, 500)

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        timerVisible: true,
      }))

      setAnswerTimestamp(Date.now())

      if (userSlideRef && session?.mode === 'challenge') {
        userSlideRef.set(
          {
            timerStartedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true },
        )
      }
    }, 1200)
  }, [])

  useEffect(() => {
    if (status === 'success') {
      if (userSlide?.answerArr) {
        const orderedOptions = userSlide?.answerArr.map((id) =>
          shuffledOptions.find((opt) => opt.id === id),
        )
        setTempOptions(orderedOptions)
      } else if (shuffledOptions) {
        setTempOptions(shuffledOptions)
      }
    }
  }, [shuffledOptions, userSlide, status])

  useEffect(() => {
    if (
      options &&
      status === 'success' &&
      tempOptions.length > 0 &&
      (isAnswerShown || isTimerComplete)
    ) {
      setTempOptions((val) =>
        val.map((item) => ({
          ...item,
          buttonVariant: getButtonVariant(item.id),
        })),
      )
    }
  }, [isAnswerShown, isTimerComplete, status, options, tempOptions])

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const newTempOptions = arrayMoveImmutable(tempOptions, oldIndex, newIndex)
    setTempOptions(newTempOptions)
  }

  const onSubmit = async () => {
    const answerArr = tempOptions.map((option) => option.id)
    userSlideRef.set({
      answerArr,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      isAnswerShown: true,
      answerTime: Date.now() - answerTimestamp,
    })
  }

  const timer = slide?.settings?.timer || 30

  const onTimerComplete = () => {
    setTimeout(() => {
      userSlideRef.update({
        isTimerComplete: true,
        isAnswerShown: true,
      })
    }, 1000)
  }

  const isTimerVisible = isChallenge
    ? session?.settings?.showTimer &&
      timer &&
      visibility.timerVisible &&
      !isAnswerShown
    : presentation?.settings?.showPlayerTimer &&
      isTimerStarted &&
      !isTimerComplete &&
      !userAnswerArr?.length

  return (
    <div className="flex h-full w-full">
      {isTimerVisible && (
        <div className="fixed left-1/2 right-auto top-2 -translate-x-1/2 transform">
          <JackInTheBox effect="bounce">
            <Timer
              duration={timer}
              onComplete={onTimerComplete}
              theme={theme}
            />
          </JackInTheBox>
        </div>
      )}
      <SlideLayout
        slide={slide}
        text={
          <Fade className="h-full w-full" triggerOnce>
            <TextfitResponsive
              className="formatted-content text-main relative mx-auto flex h-full w-full flex-col justify-center text-gray-900"
              style={{ lineHeight: '1.125' }}
              mode="multi"
            >
              <SlideContent
                content={slide.content || slide.title}
                placeholder={t('slide.questionText')}
              />
            </TextfitResponsive>
          </Fade>
        }
        media={
          <Fade className="relative mb-4 h-full w-full flex-1">
            <div className="h-full flex-1 items-center">
              <div className="h-full w-full">
                <SlideMedia slide={slide} />
              </div>
            </div>
          </Fade>
        }
        options={
          <div>
            <div
              className={`
              ${isMobile ? '-my-1' : '-mx-2'}
                  `}
            >
              <SortableList
                axis={isMobile ? 'y' : 'x'}
                lockAxis={isMobile ? 'y' : 'x'}
                options={tempOptions}
                onSortEnd={handleSortEnd}
                disabled={isAnswerShown || isTimerComplete}
                isMobile={isMobile}
              />
            </div>
            <div className="mx-auto w-full max-w-xl">
              {(!isChallenge ||
                (isChallenge && !isAnswerShown) ||
                userSlide?.correct === undefined) && (
                <Button
                  className="mt-4"
                  disabled={isTimerComplete || userAnswerArr.length > 0}
                  onClick={onSubmit}
                  type="submit"
                  size="responsive"
                  variant="success"
                  block
                >
                  {userAnswerArr.length > 0
                    ? t('user.answerForm.accepted')
                    : t('user.answerForm.answer')}
                </Button>
              )}
            </div>
          </div>
        }
      />
    </div>
  )
}
