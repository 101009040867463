import React, { useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Fade } from 'react-awesome-reveal'

import RevealPlaceholder from 'components/RevealPlaceholder'
import SlideContent from 'components/SlideContent'
import SlideLayout from 'components/SlideLayout'
import SlideMedia from 'components/SlideMedia'
import SlideMediaUpload from 'components/SlideMediaUpload'
import TextfitResponsive from 'components/TextfitResponsive'

export default function Challenge({ slide, slideRef, isEditor }) {
  const { t } = useTranslation()
  const [visibility, setVisibility] = useState({})

  useEffect(() => {
    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        textVisible: true,
      }))
    }, 0)

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        imageVisible: true,
      }))
    }, 300)
  }, [])

  if (!slide) {
    return t('common.loading')
  }

  return (
    <div className="flex h-full w-full">
      <div className="flex h-full w-full flex-1 flex-col p-4">
        <SlideLayout
          slide={slide}
          text={
            <RevealPlaceholder visible={visibility.textVisible}>
              <Fade className="h-full w-full" triggerOnce>
                <TextfitResponsive
                  className="formatted-content text-main relative mx-auto flex h-full w-full flex-col justify-center text-gray-900"
                  style={{ lineHeight: '1.125' }}
                  mode="multi"
                >
                  <SlideContent
                    content={slide.content || slide.title}
                    placeholder={t('slide.text')}
                  />
                </TextfitResponsive>
              </Fade>
            </RevealPlaceholder>
          }
          media={
            <RevealPlaceholder visible={visibility.imageVisible}>
              <Fade className="relative mb-4 h-full w-full flex-1" triggerOnce>
                <div className="h-full flex-1 items-center">
                  <div className="h-full w-full">
                    {isEditor ? (
                      <SlideMediaUpload slide={slide} slideRef={slideRef} />
                    ) : (
                      <SlideMedia slide={slide} />
                    )}
                  </div>
                </div>
              </Fade>
            </RevealPlaceholder>
          }
        />
      </div>
    </div>
  )
}
