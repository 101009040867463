import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Flip } from 'react-awesome-reveal'

const PointsAlert = ({ points = 0 }) => {
  const [prevPoints, setPrevPoints] = useState(points)
  const [diff, setDiff] = useState(0)
  const [active, setActive] = useState(false)

  useEffect(() => {
    const pointsDiff = points - prevPoints

    if (pointsDiff !== 0) {
      setPrevPoints(points)
      setDiff(pointsDiff)
      setActive(true)
      setTimeout(() => {
        setActive(false)
      }, 2000)
    }
  }, [points])

  const className = classNames([
    'rounded-lg shadow-2xl font-medium text-9xl p-4 text-white',
    diff > 0 ? 'bg-emerald-600 bg-success' : 'bg-red-600 bg-danger',
  ])

  if (active) {
    return (
      <div className="fixed inset-y-32 left-0 w-full flex justify-center items-center z-10">
        <Flip direction="vertical">
          <div className="container mx-auto flex justify-center px-4">
            <div className={className}>
              {diff > 0 ? '+' : '-'}
              {diff}
            </div>
          </div>
        </Flip>
      </div>
    )
  }

  return null
}

export default PointsAlert
