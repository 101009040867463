import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import { useTranslation } from 'next-i18next'
import { Fade, JackInTheBox } from 'react-awesome-reveal'
import { Controller, useForm } from 'react-hook-form'
import { useFirestoreDocData } from 'reactfire'

import Button from 'components/Button'
import Input from 'components/Input'
import NumberSlider from 'components/NumberSlider'
import SlideContent from 'components/SlideContent'
import SlideLayout from 'components/SlideLayout'
import SlideMedia from 'components/SlideMedia'
import TextfitResponsive from 'components/TextfitResponsive'
import Timer from 'components/Timer'

export default function Challenge({
  isChallenge,
  presentation,
  session,
  slide,
  slideId,
  sessionSlide,
  sessionRef,
  uid,
  theme,
}) {
  const [visibility, setVisibility] = useState({})
  const [answerTimestamp, setAnswerTimestamp] = useState(null)
  const { t } = useTranslation()
  const userSlideRef = sessionRef
    .collection('slides')
    .doc(slideId)
    .collection('users')
    .doc(uid)

  const { data: userSlide } = useFirestoreDocData(userSlideRef)

  const answerModeScale = slide?.question?.answerModeScale

  const userAnswer =
    typeof userSlide?.answer === 'number' ? userSlide?.answer : null
  const answerSubmitted = userAnswer !== null
  // eslint-disable-next-line no-nested-ternary
  const defaultValue = answerSubmitted
    ? userAnswer
    : answerModeScale
      ? slide?.question?.answerScale?.min
      : null

  const { control, handleSubmit } = useForm({
    defaultValues: { answer: defaultValue },
  })

  const isTimerStarted = isChallenge
    ? userSlide?.isTimerStarted
    : sessionSlide?.isTimerStarted

  const isTimerComplete = isChallenge
    ? userSlide?.isTimerComplete
    : sessionSlide?.isTimerComplete

  const isAnswerShown = isChallenge
    ? userSlide?.isAnswerShown
    : sessionSlide?.isAnswerShown

  const onSubmit = (data) => {
    userSlideRef.set({
      answer: data.answer,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      isAnswerShown: true,
      answerTime: Date.now() - answerTimestamp,
    })
  }

  const timer = slide?.settings?.timer || 30

  useEffect(() => {
    setAnswerTimestamp(Date.now())

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        textVisible: true,
      }))
    }, 0)

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        imageVisible: true,
      }))
    }, 300)

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        timerVisible: true,
      }))

      setAnswerTimestamp(Date.now())

      if (userSlideRef && session?.mode === 'challenge') {
        userSlideRef.set(
          {
            timerStartedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true },
        )
      }
    }, 1000)
  }, [])

  const onTimerComplete = () => {
    setTimeout(() => {
      userSlideRef.update({
        isTimerComplete: true,
        isAnswerShown: true,
      })
    }, 1000)
  }

  const isTimerVisible = isChallenge
    ? session?.settings?.showTimer &&
      timer &&
      visibility.timerVisible &&
      !isAnswerShown
    : presentation?.settings?.showPlayerTimer &&
      isTimerStarted &&
      !isTimerComplete &&
      !answerSubmitted

  return (
    <div className="flex h-full w-full">
      {isTimerVisible && (
        <div className="fixed left-1/2 right-auto top-2 -translate-x-1/2 transform">
          <JackInTheBox effect="bounce">
            <Timer
              duration={timer}
              onComplete={onTimerComplete}
              theme={theme}
            />
          </JackInTheBox>
        </div>
      )}
      <SlideLayout
        slide={slide}
        text={
          <Fade className="h-full w-full" triggerOnce>
            <TextfitResponsive
              className="formatted-content text-main relative mx-auto flex h-full w-full flex-col justify-center text-gray-900"
              style={{ lineHeight: '1.125' }}
              mode="multi"
            >
              <SlideContent
                content={slide.content || slide.title}
                placeholder={t('slide.questionText')}
              />
            </TextfitResponsive>
          </Fade>
        }
        media={
          <Fade className="relative mb-4 h-full w-full flex-1">
            <div className="h-full flex-1 items-center">
              <div className="h-full w-full">
                <SlideMedia slide={slide} />
              </div>
            </div>
          </Fade>
        }
        options={
          <form
            className="mx-auto w-full max-w-xl"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              {!answerModeScale && (
                <Controller
                  control={control}
                  name="answer"
                  defaultValue={defaultValue}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      disabled={isTimerComplete || userAnswer}
                      size="lg"
                      type="number"
                      inputMode="numeric"
                      value={value}
                      onChange={(event) =>
                        onChange(parseInt(event.target.value))
                      }
                      className={`form-input text-center
                 ${
                   answerSubmitted && userSlide?.correct === true
                     ? 'animate__animated animate__tada !border-emerald-600'
                     : ''
                 }
                
                ${
                  answerSubmitted && userSlide?.correct === false
                    ? 'animate__animated animate__shakeX !border-red-600'
                    : ''
                }
                
                `}
                      placeholder={t('user.answerForm.yourAnswer')}
                      required
                      autoComplete="off"
                    />
                  )}
                />
              )}
              {answerModeScale && (
                <div className="px-2">
                  <Controller
                    control={control}
                    name="answer"
                    defaultValue={defaultValue}
                    render={({ field: { value, onChange } }) => (
                      <NumberSlider
                        input
                        min={slide?.question?.answerScale?.min}
                        max={slide?.question?.answerScale?.max}
                        step={slide?.question?.answerScale?.step}
                        answer={isAnswerShown ? slide?.question?.answer : null}
                        disabled={isTimerComplete || answerSubmitted}
                        theme={theme}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div>
              {(!isChallenge ||
                (isChallenge &&
                  !answerSubmitted &&
                  userSlide?.correct === undefined)) && (
                <Button
                  className="mt-4"
                  disabled={isTimerComplete || answerSubmitted}
                  size="responsive"
                  variant="success"
                  block
                  type="submit"
                >
                  {answerSubmitted
                    ? t('user.answerForm.accepted')
                    : t('user.answerForm.answer')}
                </Button>
              )}
            </div>
          </form>
        }
      />
    </div>
  )
}
