import React from 'react'
import useThrottledMemo from 'hooks/useMemoThrottle'
import { useTranslation } from 'next-i18next'
import { useFirestoreDocData } from 'reactfire'

import Chart from 'components/Chart'
import SlideContent from 'components/SlideContent'
import TextfitResponsive from 'components/TextfitResponsive'

export default function Slide({
  isPreview,
  isEditor,
  slide,
  slideId,
  sessionRef,
}) {
  const { t } = useTranslation()
  const question = slide?.question || {}
  const options = question?.options || []

  const slidePollRef = sessionRef.collection('slidePolls').doc(slideId)
  const { data: slidePoll } = useFirestoreDocData(slidePollRef)

  if (!slide) {
    return t('common.loading')
  }

  const labels = options.map((option, index) => ({
    text: option.value || t('slide.option', { option: index + 1 }),
    imageUrl: option.imageUrl,
  }))

  let data = useThrottledMemo(
    () => options.map((option) => (slidePoll ? slidePoll[option.id] : 0) || 0),
    1000,
    [options, slidePoll],
  )

  if (isPreview || isEditor) {
    data = options.map((option, index) => (index + 1) * 10)
  }

  return (
    <div className="flex h-full w-full flex-col justify-center">
      <div className="flex h-full w-full flex-1 flex-col">
        <div className="flex h-1/4 w-full items-start">
          <TextfitResponsive
            className="formatted-content text-main relative mx-auto flex h-full w-full flex-col justify-center text-gray-900"
            style={{ lineHeight: '1.125' }}
            mode="multi"
          >
            <SlideContent
              content={slide.content || slide.title}
              placeholder={t('slide.questionText')}
            />
          </TextfitResponsive>
        </div>
        <div className="relative h-full w-full flex-1">
          <div className="absolute left-0 top-0 h-full w-full">
            <Chart labels={labels} data={data} type={slide?.chartType} />
          </div>
        </div>
      </div>
    </div>
  )
}
