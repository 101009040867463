import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Zoom } from 'react-awesome-reveal'
import { useForm } from 'react-hook-form'
import { useFirestoreDocData } from 'reactfire'

import Button from 'components/Button'
import Input from 'components/Input'
import SlideContent from 'components/SlideContent'
import TextfitResponsive from 'components/TextfitResponsive'
import hasBadWords from 'utils/hasBadWords'

import Results from './components/Results'

export default function Challenge({
  isChallenge,
  presentation,
  session,
  slide,
  slideId,
  sessionRef,
  uid,
}) {
  const [isProfanity, setIsProfanity] = useState(false)
  const { t } = useTranslation()
  const { register, handleSubmit } = useForm()
  const userSlideRef = sessionRef
    .collection('slides')
    .doc(slideId)
    .collection('users')
    .doc(uid)

  const slidePollRef = sessionRef.collection('slidePolls').doc(slideId)

  const { data: userSlide } = useFirestoreDocData(userSlideRef)

  const userAnswer = userSlide?.answerArr || []
  const isAnswerShown = userSlide?.isAnswerShown

  const profanityFilter = presentation?.settings?.profanityFilter

  const onSubmit = async (data) => {
    const answers = Object.values(data || {}).filter((answer) => answer)
    if (!answers.length) return
    if (profanityFilter) {
      if (answers.some(hasBadWords)) {
        setIsProfanity(true)
        return
      }
      setIsProfanity(false)
    }

    userSlideRef.set({
      answerArr: answers,
      isAnswerShown: true,
    })

    slidePollRef.set(
      {
        [uid]: answers,
      },
      { merge: true },
    )
  }

  const maxAnswers = slide?.settings?.maxAnswers || 1
  const answersArray = Array.from({ length: maxAnswers }, (_, i) => i + 1)

  const showPollResults =
    isChallenge && isAnswerShown && session?.settings?.showPollResults

  return (
    <div className="flex h-full w-full">
      <div className="flex h-full w-full flex-1 flex-col">
        {showPollResults ? (
          <>
            <div className="flex h-full flex-col">
              <Results
                slide={slide}
                slideId={slideId}
                sessionRef={sessionRef}
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-1 flex-col justify-center">
              <Zoom className="h-full">
                <TextfitResponsive
                  className="formatted-content text-main relative mx-auto flex h-full w-full flex-col justify-center text-gray-900"
                  style={{ lineHeight: '1.125' }}
                  mode="multi"
                >
                  <SlideContent
                    content={slide.content || slide.title}
                    placeholder={t('slide.text')}
                  />
                </TextfitResponsive>
              </Zoom>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="mx-auto mt-4 w-full max-w-xl"
            >
              <div>
                {profanityFilter && isProfanity && (
                  <div className="text-center text-red-600">
                    Пожалуйста, не ругайтесь
                  </div>
                )}
                {answersArray.map((answer) => (
                  <Input
                    key={answer}
                    disabled={userAnswer?.length > 0}
                    {...register(`answer-${answer}`)}
                    size={
                      // eslint-disable-next-line no-nested-ternary
                      maxAnswers > 3 ? (maxAnswers > 5 ? 'sm' : 'base') : 'lg'
                    }
                    type="text"
                    className="form-input rounded border-red-600 text-center"
                    placeholder={t('user.answerForm.yourAnswer')}
                    autoComplete="off"
                    maxLength={30}
                  />
                ))}
              </div>
              {(!isChallenge || (isChallenge && !userAnswer?.length)) && (
                <div>
                  <Button
                    className="mt-4"
                    disabled={userAnswer?.length > 0}
                    type="submit"
                    size="lg"
                    variant="success"
                    block
                  >
                    {t('user.answerForm.answer')}
                  </Button>
                </div>
              )}
            </form>
          </>
        )}
      </div>
    </div>
  )
}
