import { useMemo, useState } from 'react'
import axios from 'axios'
import { nanoid } from 'nanoid'
import { useTranslation } from 'next-i18next'
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'

import Button from 'components/Button'
import Input from 'components/Input'

import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

export default function UserFieldsForm({ userFields, sessionId, uid }) {
  const [cookies, setCookie] = useCookies(['default_player_name'])
  const { t } = useTranslation()
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const { register, handleSubmit } = useForm({
    defaultValues: { name: cookies.default_player_name || '' },
  })

  const onSubmit = async ({ ...fields }) => {
    setLoading(true)
    const name =
      fields.name ||
      fields.email ||
      fields.phone ||
      Object.values(fields || {}).find((field) => field)
    nanoid()
    try {
      if (name) {
        setCookie('default_player_name', name, { path: '/' })
        await axios.post('/api/player/set-name', { name, sessionId, uid })
        await axios.post('/api/player/set-fields', { fields, sessionId, uid })
      }
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  const formFields = useMemo(() => {
    const fields = []
    if (userFields?.name) {
      fields.push({
        name: 'name',
        label: t('user.userFields.name'),
        type: 'text',
        required: true,
        defaultValue: cookies.default_player_name || '',
      })
    }
    if (userFields?.email) {
      fields.push({
        name: 'email',
        label: t('user.userFields.email'),
        type: 'email',
        required: true,
      })
    }
    if (userFields?.phone) {
      fields.push({
        name: 'phone',
        label: t('user.userFields.phone'),
        type: 'tel',
        required: true,
      })
    }
    if (userFields?.customFields) {
      userFields.customFields.forEach((field, index) => {
        fields.push({
          name: `field_${index}`,
          label: field.title,
          type: field.type,
          required: true,
        })
      })
    }
    return fields
  }, [userFields])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          {formFields.map((field) => (
            <>
              <div className="mb-4">
                <Input
                  {...register(field.name)}
                  type={field.type}
                  className="border-text form-input rounded  text-center placeholder-gray-400"
                  placeholder={field.label}
                  required={field.required}
                  disabled={loading}
                  size="lg"
                />
              </div>
              {field.name === 'name' && (
                <>
                  {' '}
                  {error && (
                    <div className="-mt-4 mb-2 text-center text-red-600">
                      {t('user.nameForm.nameTaken')}
                    </div>
                  )}
                </>
              )}
            </>
          ))}
        </div>
        <Button
          variant="success"
          type="submit"
          block
          loading={loading}
          size="lg"
        >
          {t('user.nameForm.enter')}
        </Button>
      </form>
    </div>
  )
}
