import plans from 'const/plans'
import {
  addWeeks,
  isAfter,
  isSameDay,
  startOfDay,
  startOfToday,
} from 'date-fns'
import useUserProfile from 'hooks/useUserProfile'

const useUserPlan = (uid) => {
  const { data: profile } = useUserProfile(uid)

  const today = startOfToday()

  if (!profile) {
    return null
  }

  const planId = profile?.plan?.id || 'free'

  const isOverdue =
    planId !== 'free' &&
    !profile?.plan?.hasActiveSubscription &&
    profile?.plan?.paidTill &&
    (isAfter(today, new Date(profile?.plan?.paidTill)) ||
      isSameDay(today, new Date(profile?.plan?.paidTill)))

  const expireIn1Week =
    planId !== 'free' &&
    profile?.plan?.paidTill &&
    !profile?.plan?.hasActiveSubscription &&
    !isOverdue &&
    isAfter(
      startOfDay(addWeeks(today, 1)),
      startOfDay(new Date(profile?.plan?.paidTill)),
    )

  const overduePlan = {
    ...plans.free,
    title: plans[planId].title,
    id: planId,
    price: plans[planId].price,
    subscriptionUrl: plans[planId].subscriptionUrl,
    subscriptionId: plans[planId].subscriptionId,
  }

  return {
    hasActiveSubscription: profile?.plan?.hasActiveSubscription,
    plan: isOverdue ? overduePlan : plans[planId],
    paidTill: profile?.plan?.paidTill,
    isOverdue,
    expireIn1Week,
    userLimit: profile?.plan?.userLimit || plans[planId]?.userLimit,
  }
}

export default useUserPlan
