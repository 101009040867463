export default {
  free: {
    id: 'free',
    title: 'free',
    limits: {
      polls: 2,
      questions: 5,
    },
    price: {
      month: 0,
      year: 0,
      monthYearly: 0,
    },
    features: {
      onlineSupport: true,
      ai: true,
    },
    branding: {
      defaultThemes: true,
      themeEdit: false,
      logo: false,
    },
  },
  basic: {
    id: 'basic',
    title: 'basic',
    subscriptionId: '94b90ecc3ed3a11',
    subscriptionUrl: 'https://unislide.ainox.pro/94b90ecc3ed3a11',
    price: {
      month: 49000,
      year: 588000,
      monthYearly: 49000,
    },
    features: {
      exportToXls: true,
      imagesLibrary: true,
      onlineSupport: true,
      ai: true,
    },
    branding: {
      defaultThemes: true,
      themeEdit: false,
      logo: false,
    },
  },
  pro: {
    id: 'pro',
    title: 'pro',
    subscriptionId: 'f6deb41086443eb',
    subscriptionUrl: 'https://unislide.ainox.pro/f6deb41086443eb',
    price: {
      month: 99000,
      year: 1188000,
      monthYearly: 99000,
    },
    features: {
      branding: true,
      exportToXls: true,
      onlineSupport: true,
      prioritySupport: false,
      imagesLibrary: true,
      userFields: true,
      ai: true,
    },
    branding: {
      defaultThemes: true,
      themeEdit: true,
      logo: true,
    },
    other: {
      payByBill: true,
    },
  },
  business: {
    hidden: true,
    id: 'business',
    title: 'business',
    price: {
      month: 'custom',
      year: 'custom',
    },
    request: true,
    features: {
      branding: true,
      exportToXls: true,
      onlineSupport: true,
      prioritySupport: true,
      imagesLibrary: true,
      onboarding: true,
      userFields: true,
      ai: true,
    },
    branding: {
      defaultThemes: true,
      themeEdit: true,
      logo: true,
    },
    other: {
      payByBill: true,
    },
  },
}

export const features = {}
