import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import { useTranslation } from 'next-i18next'
import { Fade, Flip, JackInTheBox } from 'react-awesome-reveal'
import { useForm } from 'react-hook-form'
import { useFirestoreDocData } from 'reactfire'

import Button from 'components/Button'
import Input from 'components/Input'
import RevealPlaceholder from 'components/RevealPlaceholder'
import SlideContent from 'components/SlideContent'
import SlideLayout from 'components/SlideLayout'
import SlideMedia from 'components/SlideMedia'
import TextfitResponsive from 'components/TextfitResponsive'
import Timer from 'components/Timer'
import hasBadWords from 'utils/hasBadWords'

export default function Challenge({
  isChallenge,
  presentation,
  session,
  slide,
  slideId,
  sessionSlide,
  sessionRef,
  uid,
  theme,
}) {
  const [visibility, setVisibility] = useState({})
  const [answerTimestamp, setAnswerTimestamp] = useState(null)
  const { t } = useTranslation()
  const [isProfanity, setIsProfanity] = useState(false)
  const userSlideRef = sessionRef
    .collection('slides')
    .doc(slideId)
    .collection('users')
    .doc(uid)

  const { data: userSlide } = useFirestoreDocData(userSlideRef)

  const profanityFilter = presentation?.settings?.profanityFilter

  const userAnswer = userSlide?.answer || ''

  const isTimerStarted = isChallenge
    ? userSlide?.isTimerStarted
    : sessionSlide?.isTimerStarted

  const isTimerComplete = isChallenge
    ? userSlide?.isTimerComplete
    : sessionSlide?.isTimerComplete

  const isAnswerShown = isChallenge
    ? userSlide?.isAnswerShown
    : sessionSlide?.isAnswerShown

  const { register, handleSubmit } = useForm({
    defaultValues: { answer: userAnswer },
  })

  const onSubmit = async (data) => {
    if (profanityFilter) {
      if (hasBadWords(data.answer)) {
        setIsProfanity(true)
        return
      }
      setIsProfanity(false)
    }
    userSlideRef.set({
      answer: data.answer,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      isAnswerShown: true,
      answerTime: Date.now() - answerTimestamp,
    })
  }

  const timer = slide?.settings?.timer || 30

  useEffect(() => {
    setAnswerTimestamp(Date.now())

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        textVisible: true,
      }))
    }, 0)

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        imageVisible: true,
      }))
    }, 300)

    setTimeout(() => {
      setVisibility((state) => ({
        ...state,
        timerVisible: true,
      }))

      setAnswerTimestamp(Date.now())

      if (userSlideRef && session?.mode === 'challenge') {
        userSlideRef.set(
          {
            timerStartedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true },
        )
      }
    }, 1000)
  }, [])

  const onTimerComplete = () => {
    setTimeout(() => {
      userSlideRef.update({
        isTimerComplete: true,
        isAnswerShown: true,
      })
    }, 1000)
  }

  const isTimerVisible = isChallenge
    ? session?.settings?.showTimer &&
      timer &&
      visibility.timerVisible &&
      !isAnswerShown
    : presentation?.settings?.showPlayerTimer &&
      isTimerStarted &&
      !isTimerComplete &&
      !userAnswer

  const answer = userSlide?.questionAnswer

  return (
    <div className="flex h-full w-full">
      {isTimerVisible && (
        <div className="fixed left-1/2 right-auto top-2 -translate-x-1/2 transform">
          <JackInTheBox effect="bounce">
            <Timer
              duration={timer}
              onComplete={onTimerComplete}
              theme={theme}
            />
          </JackInTheBox>
        </div>
      )}
      <SlideLayout
        slide={slide}
        text={
          <Fade className="h-full w-full" triggerOnce>
            <TextfitResponsive
              className="formatted-content text-main relative mx-auto flex h-full w-full flex-col justify-center text-gray-900"
              style={{ lineHeight: '1.125' }}
              mode="multi"
            >
              <SlideContent
                content={slide.content || slide.title}
                placeholder={t('slide.questionText')}
              />
            </TextfitResponsive>
          </Fade>
        }
        media={
          <Fade className="relative mb-4 h-full w-full flex-1">
            <div className="h-full flex-1 items-center">
              <div className="h-full w-full">
                <SlideMedia slide={slide} />
              </div>
            </div>
          </Fade>
        }
        options={
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mx-auto w-full max-w-xl"
          >
            <div>
              {profanityFilter && isProfanity && (
                <div className="text-center text-red-600">
                  Пожалуйста, не ругайтесь
                </div>
              )}
              <Input
                disabled={isTimerComplete || userAnswer}
                {...register('answer')}
                size="lg"
                type="text"
                className={`form-input text-center
                 ${
                   userAnswer && userSlide?.correct === true
                     ? 'animate__animated animate__tada !border-emerald-600'
                     : ''
                 }
                
                ${
                  userAnswer && userSlide?.correct === false
                    ? 'animate__animated animate__shakeX !border-red-600'
                    : ''
                }
                
                `}
                placeholder={t('user.answerForm.yourAnswer')}
                required
                autoComplete="off"
              />
            </div>
            <div>
              {(!isChallenge ||
                (isChallenge &&
                  !userAnswer &&
                  userSlide?.correct === undefined)) && (
                <Button
                  className="mt-4"
                  disabled={isTimerComplete || !!userAnswer}
                  type="submit"
                  size="responsive"
                  variant="success"
                  block
                >
                  {userAnswer
                    ? t('user.answerForm.accepted')
                    : t('user.answerForm.answer')}
                </Button>
              )}
            </div>
          </form>
        }
      />

      {isAnswerShown && (
        <div className="absolute bottom-20 left-0 top-20 flex w-full items-center justify-center">
          <RevealPlaceholder visible={isAnswerShown}>
            <Flip direction="vertical">
              <div className="container mx-auto flex justify-center px-4">
                <div className="bg-success rounded-lg bg-emerald-600 p-4 text-center text-xl font-medium text-white shadow-2xl md:text-9xl">
                  {answer}
                </div>
              </div>
            </Flip>
          </RevealPlaceholder>
        </div>
      )}
    </div>
  )
}
